import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./home/Home";
import Gallery from "./pages/gallery/Gallery";
import Footer from "./components/footer/Footer";
import AboutUs from "./pages/about-us/AboutUs";
import Contacts from "./pages/contacts/Contacts";
import Blogs from "./pages/blogs/Blogs";
import Donate from "./pages/donate/Donate";
import WorksHome from "./pages/our-works/WorksHome";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<AboutUs />} />
        <Route path="/our-works" exact element={<WorksHome />} />
        <Route path="/blogs" exact element={<Blogs />} />
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/contacts" exact element={<Contacts />} />
        <Route path="/donate" exact element={<Donate />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
