import ImageOne from "../images/slider/slider1.jpg";
import ImageTwo from "../images/slider/slider2.jpg";
import ImageThree from "../images/slider/slider3.jpg";
import ImageFour from "../images/slider/slider4.jpg";

export const dataSlider = [
  {
    image: ImageOne,
    alt: "slide1",
  },

  {
    image: ImageTwo,
    alt: "slide2",
  },

  {
    image: ImageThree,
    alt: "slide3",
  },
  {
    image: ImageFour,
    alt: "slide4",
  },
];
