import React from "react";
import "./Donate.css";

const Donate = () => {
  return (
    <div className="donate-page">
      <div className="donate__page-title">
        <h1>Please Donate</h1>
      </div>
      <div className="donate-container">
        <div className="donate-content">
          <div className="donate__left-side">
            <div className="donate-address donate-details">
              <div className="donate-topic">Organization Name</div>
              <div className="donate__text-one">
                Good News Ministries Jail & Prisoners Welfare Society
              </div>
            </div>
            <div className="donate-phone donate-details">
              <div className="donate-topic">Bank Account Number</div>
              <div className="donate__text-one">13510200008038</div>
            </div>
            <div className="donate-email donate-details">
              <div className="donate-topic">Swift Code</div>
              <div className="donate__text-one">FDRLINBBIBD</div>
            </div>
            <div className="donate-email donate-details">
              <div className="donate-topic">Routing Code</div>
              <div className="donate__text-one">
                CHIPS 0256| Fed Wire ABA 026009593
              </div>
            </div>
            <div className="donate-email donate-details">
              <div className="donate-topic">IFSC Code</div>
              <div className="donate__text-one">FDRL 0001351</div>
            </div>
          </div>
          <div className="donate__right-side">
            <div className="donate__topic-text">
              Please Donate us to help needy children.
            </div>
            <p>
              Every time you invest in transforming an inmate's life....... you
              are very likely to affect an entire family- for eternity.
            </p>
            <form action="#">
              <div className="donate__input-box">
                <input type="text" placeholder="Enter your name"></input>
              </div>
              <div className="donate__input-box">
                <input type="text" placeholder="Enter your email"></input>
              </div>
              <div className="donate__input-box donate__message-box">
                <textarea
                  name="name"
                  rows="8"
                  cols="80"
                  placeholder="Enter your message here"
                ></textarea>
              </div>
              <div className="donate-button">
                <input type="button" value="Send Now"></input>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
