import React, { useState } from "react";
import "./Photos.css";
import { CgCloseR } from "react-icons/cg";

const Photos = () => {
  let data = [
    {
      id: 1,
      imgSrc: "/images/gallery/g1.jpg",
    },
    {
      id: 2,
      imgSrc: "/images/gallery/g2.jpg",
    },
    {
      id: 3,
      imgSrc: "/images/gallery/g3.jpeg",
    },
    {
      id: 4,
      imgSrc: "/images/gallery/g4.jpg",
    },
  ];

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  return (
    <div>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} alt="" />
        <CgCloseR onClick={() => setModel(false)} />
      </div>
      <div className="photos_container">
        <h1 style={{ textAlign: "center" }}>Memories</h1>
        <div className="photos">
          {data.map((item, index) => {
            return (
              <div
                className="pictures"
                key={index}
                onClick={() => getImg(item.imgSrc)}
              >
                <img src={item.imgSrc} alt="" style={{ width: "100%" }} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Photos;
