import React from "react";
import "./Guest.css";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <div className="guest__section">
        <div className="guest__wrapper">
          <h1 className="guest__heading">Guest Messages</h1>
          <div className="guest__container">
            <Link to="/" className="guest__container-card">
              <div className="guest__container-cardInfo">
                <h4>You must follow</h4>
                <h3>GNMC</h3>
                <p>
                  These precious and inspiring stories demonstrate the
                  life-transforming power that is available through the gospel
                  of grace! As a pastor, my heart is to have many others who are
                  still in bondage experience this amazing grace that is found
                  in our loving Savior! Will you join me?).
                </p>
              </div>
            </Link>
            <Link to="/" className="guest__container-card">
              <div className="guest__container-cardInfo">
                <h4>But I must follow</h4>
                <h3>GNMC</h3>
                <p>
                  "Your monthly support is the KEY to helping many more who are
                  living in bondage know, through our TV broadcasts, new media
                  technology, and humanitarian outreaches, the love and freedom
                  our beautiful Savior offers! Join us today. Be part of the
                  grace revolution that’s changing life stories!
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default About;
