import React from "react";
import "./About.css";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <div className="aboutus__section">
        <div className="aboutus__wrapper">
          <h1 className="aboutus__heading">Know Us</h1>
          <p className="aboutus__description">
            Passionate about genuinely encountering God’s transforming grace and
            building a grace-based community, GNMC is a place of family,
            encouragement, and support that is anchored on seeing more and more
            of Jesus, and living the glorious lives He’s called us to. We gather
            for services every Sunday where the life-changing gospel is
            preached, and also hang out with one another on other days of the
            week. We meet in Care Groups twice a month to share the Word and our
            lives, pray for one another, and worship the Lord together. It
            doesn’t matter which season of life you’re in—if you’re single,
            married, still in school, or a senior—there’s a place for you here!.
          </p>
          <div className="aboutus__container">
            <Link to="/" className="aboutus__container-card">
              <div className="aboutus__container-cardInfo">
                <h4>Vision</h4>
                <p>
                  All those impacted by incarceration live with dignity as
                  contributing members of our communities.
                </p>
              </div>
            </Link>
            <Link to="/" className="aboutus__container-card">
              <div className="aboutus__container-cardInfo">
                <h4>Mission</h4>
                <p>
                  Improve the quality of life for families, children and
                  communities impacted by incarceration, to assist with
                  successful community reentry and family reunification for
                  those transitioning from confinement to freedom.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default About;
