export const worksObjOne = {
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Child Development",
  headline: "",
  description:
    "This project aims to promote education of out-of-school children, including those who are either differently abled or otherwise at risk, by facilitating their access to mainstream State-funded primary/elementary schools so that they are enabled to complete studies.",
  imgStart: "",
  img: "images/works/w1.jpg",
  alt: "image",
};

export const worksObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Prisoner Welfare",
  headline: "",
  description:
    "We provide Emotional, psychological, Spiritual Guidance, Civic Education and practical support to reduce the mental agonies of the inmates of Jails and Prisons, irrespective of ethnic origins, Sex, Caste, Creed and Nationalities within the Indian Criminal justice system with the literature, gifts and goodies- courtesy of festivals. Jails & Prisons.We offers confidential non-judgmental emotional support in number of ways for inmates of the Jail and prisoners to get the support they need, from our volunteers visiting jails and prisons to the Listen their agony, we also train these prisoners to offer confidential emotional support to their new fellow inmates.",
  imgStart: "start",
  img: "images/works/w2.jpg",
  alt: "image",
};

export const worksObjThree = {
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Education",
  headline: "",
  description:
    "Helping Orphans Prosper through Education. We provide quality education Providing- Text Books, stationary, conducting regular classes in English, Math, Science, Social Studies. Apart from this the children are taught Applied Learning Activities- Cooking, Sewing, Environment, Health & Hygiene, Dangers of Smoking and Alcohol, Traffic Sense, Gender Equity and Kitchen Gardens to equip them with the skills, knowledge, opportunities and self assertiveness required for them to live independently in the society. Our school's staff members are well educated and passionate about breaking the cycle of poverty in the childrens lives through education to improve their lives..",
  imgStart: "",
  img: "images/works/w3.jpg",
  alt: "image",
};

export const worksObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Street Children",
  headline: "",
  description:
    "In this life there is no better investment than to give hope to a destitute child. Children living on the streets are vulnerable to abuse, harassment and humiliation. Often, they are rounded up by the police and subjected to interrogation and lock-ups. We intervene, rescue and facilitate as sole caretakers of the Children living on streets due to poverty, parental conflicts, HIV/AIDS, parent’s suicides, Tsunami and Cyclones. These children are vulnerable to shocks and are hardly hit by the poverty, moreover they are forced to come to roadside and earn their living through begging on the streets, child prostitutes and courier for Drug peddler’s for their survival, eventually leading them to become Alcoholics, Drug Addicts or drug peddlers and HIV/ AIDS victims. These miserable children are taking refuge under bridges, on streets, pavements, under shops entrance, railway stations, outside hospitals and public places etc..",
  imgStart: "start",
  img: "images/works/w4.jpg",
  alt: "image",
};

export const worksObjFive = {
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Medical Care",
  headline: "",
  description:
    "Saving Lives Today For Nourishing Communities Tomorrow. We provide medical and health care services to meet the immediate health care needs of the marginalized and vulnerable communities living in unreached and under reached remote rural areas and slums of the towns through standalone medical camps. We organize customized health camps extensively in the unreached and under reached rural areas and slums across the District offering comprehensive health services – curative, preventive, primitive and referral, to a large number of people in selected intervention areas..",
  imgStart: "",
  img: "images/works/w5.jpeg",
  alt: "image",
};

export const worksObjSix = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Orphanage",
  headline: "",
  description:
    "Most orphans have never known the warm embrace of a parent. They have never experienced the comfort and security of a home and family. They live in a world most of us cannot comprehend - stark and bleak with no opportunity of a healthy emotional and social development. We at our Orphanage change by “Lighting up the lives of the destitute.The unifying passion of our orphanage is to serve the needs of poor helpless and vulnerable orphan Children by providing. There are millions of children in orphanages around the world who need the love of parents. Make a Donation Today and Help a Child. The child's life will be changed forever as he/she is offered the love and support of a family.",
  imgStart: "start",
  img: "images/works/w6.jpg",
  alt: "image",
};

export const worksObjSeven = {
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Water Based Fire Protection System",
  headline: "",
  description:
    "There is lack of effective leadership in so many communities today because of several reasons. Many people in leadership positions simply don’t have a vision of the community’s development that they work towards; most of them are not sufficiently equipped in leadership and some leaders have no time for the future, because they are too absorbed in the present. Leader Development is an initiative to raise more visionary, effective, focused l eaders who will strive for the development of the community.",
  imgStart: "",
  img: "images/works/w1.jpg",
  alt: "image",
};
