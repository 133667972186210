import React from "react";
import Slider from "../components/slider/Slider";
import { dataSlider } from "../components/slider/dataSlider";
import Work from "../components/work/Work";
import About from "../components/about/About";
import Services from "../components/services/Services";
import Guest from "../components/guest/Guest";
import Photos from "../components/photos/Photos";

const Home = () => {
  return (
    <div>
      <Slider slides={dataSlider} />
      <Work />
      <About />
      <Services />
      <Photos />
      <Guest />
    </div>
  );
};

export default Home;
