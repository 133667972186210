import React from "react";
import "./Blogs.css";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <div className="blogs__section">
        <div className="blogs__wrapper">
          <h1 className="blogs__heading">Blogs</h1>
          <div className="blogs__container">
            <Link to="/" className="aboutus__container-card">
              <div className="blogs__container-cardInfo">
                <h4>Educational Programme :</h4>
                <p>
                  A total number of 1500 children have been enrolled in this
                  programme. Out of the students enrolled about 95% of the
                  students are appearing for their examinations. One of the main
                  features of this programme is that all children are being
                  provided with school uniforms, books, fees and other
                  stationeries free of cost (Apprx Rs.54,00,000.00 per annum).
                </p>
              </div>
            </Link>
            <Link to="/" className="blogs__container-card">
              <div className="blogs__container-cardInfo">
                <h4>Women Sanghas :</h4>
                <p>
                  There are 50 Women Self Help Groups functioning under NKSST.
                  The women conduct weekly meetings on various aspects like
                  Self-Help Groups SHG). Benefits of SHGs: enhancement of self
                  esteem, community development, health, hygiene, family
                  budgeting and savings.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Blogs;
