import React from "react";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

const Navbar = () => {
  return (
    <div>
      <Nav>
        <NavLink to="/">
          <h1>GNMC India</h1>
        </NavLink>
        <Bars />
        <NavMenu>
          <NavLink to="/" activeStyle>
            Home
          </NavLink>
          <NavLink to="/about" activeStyle>
            About Us
          </NavLink>
          <NavLink to="our-works" activeStyle>
            Our Works
          </NavLink>
          <NavLink to="/blogs" activeStyle>
            Blogs
          </NavLink>
          <NavLink to="/gallery" activeStyle>
            Gallery
          </NavLink>
          <NavLink to="/contacts" activeStyle>
            Contacts
          </NavLink>
        </NavMenu>
        <NavBtn>
          <NavBtnLink to="/donate">Donate</NavBtnLink>
        </NavBtn>
      </Nav>
    </div>
  );
};

export default Navbar;
