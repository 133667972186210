import React from "react";
import OurWorks from "./OurWorks";
import {
  worksObjOne,
  worksObjTwo,
  worksObjThree,
  worksObjFour,
  worksObjFive,
  worksObjSix,
  worksObjSeven,
} from "./WorksData";

const WorksHome = () => {
  return (
    <div>
      <OurWorks {...worksObjOne} />
      <OurWorks {...worksObjTwo} />
      <OurWorks {...worksObjThree} />
      <OurWorks {...worksObjFour} />
      <OurWorks {...worksObjFive} />
      <OurWorks {...worksObjSix} />
      <OurWorks {...worksObjSeven} />
    </div>
  );
};

export default WorksHome;
