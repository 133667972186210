import React from "react";
import "./Services.css";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

const Services = () => {
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <div className="services__section">
        <div className="services__wrapper">
          <h1 className="services__heading">Achievements</h1>
          <div className="services__container">
            <Link to="/" className="services__container-card">
              <div className="services__container-cardInfo">
                <h4>
                  <CountUp end={800} duration={9} />
                </h4>
                <h3>Enquiries</h3>
              </div>
            </Link>
            <Link to="/" className="services__container-card">
              <div className="services__container-cardInfo">
                <h4>
                  <CountUp end={350} duration={9} />+
                </h4>
                <h3>Survey</h3>
              </div>
            </Link>
            <Link to="/" className="services__container-card">
              <div className="services__container-cardInfo">
                <h4>
                  <CountUp end={100} duration={9} />+
                </h4>
                <h3>Services</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Services;
