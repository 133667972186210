import React from "react";
import "./AboutUs.css";
import { IconContext } from "react-icons/lib";
import AboutCard from "./AboutCard";

const About = () => {
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <div className="about__section">
        <div className="about__wrapper">
          <h1 className="about__heading">About Us</h1>
          <p className="about__description">
            GNMCis a ministry dedicated to pray for the broken hearted people
            irrespective of caste, creed and religion. It was started in
            obedience to God's vision to serve the people through love and
            compassion of Jesus Christ.
          </p>
          <p className="about__description">
            Everyday thousands of people are longing for a blessing, a miracle
            in their lives and it is for them that the Jesus Calls Prayer Towers
            are open to pour their needs to God and receive a miracle. It is
            Jesus who performs miracles for them. Today, millions are blessed by
            the comforting messages and prayers. Prayer Festival Public
            Meetings, TV programs, Letter Ministry, Website and personal
            prayers.
          </p>
          <p className="about__description">
            We transform the lives of families and children those who are living
            under the poverty level for a compassionate recovery assistance. We
            offer a wide array of programs and services aligned with our vision
            and mission. The work of our organisation is based upon fundamental
            beliefs that transcend words; it is an expression by actions. Our
            Numerous years of working compassionately and diligently to provide
            programs and services to families and children impacted by
            incarceration and these are the testaments to our commitment. Our
            Success reflects the evidence based practices we utilize.
          </p>
          <p className="about__description">
            We guide the families of incarcerated and rehabilitates to lead a
            good life with moral & educational support for children, employable
            skill trainings, medical and permissible support for the family
            members to lead a decent life in the community. To address
            incarceration in this region with a motto “Reaching Those Others
            Have Forgotten...”
          </p>
          <div className="about__container-message">
            <div className="about__message-card">
              <AboutCard
                image="images/founder.jpg"
                title="K. Samrat"
                position="Director"
                description="K. Samrat who was born in Christian family, raised up with morals and values, love, kind, compassion and forgiveness. Came up with a zeal and vision to minimise adverse conditions in the society towards the released prisoners. Now he is tirelessly managing 20 prisons with 18 Chaplin’s reaching out 1600 prisoners. He is dedicatedly working for the past 14 years and still working with the compassion and love toward them. He has facilitated more than 66 widows, sheltered 165 orphans  and street children with the facilities. And providing skill training’s by creating a new direction to move forward with their lives."
              />
            </div>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default About;
