import React from "react";
import "./Work.css";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";

const Work = () => {
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <div className="whyus__section">
        <div className="whyus__wrapper">
          <h1 className="whyus__heading">Our Goal</h1>
          <div className="whyus__container">
            <Link to="/" className="whyus__container-card">
              <div className="whyus__container-cardInfo">
                <h3>CHILD DEVELOPMENT</h3>
              </div>
            </Link>
            <Link to="/" className="whyus__container-card">
              <div className="whyus__container-cardInfo">
                <h3>ORPHANAGE</h3>
              </div>
            </Link>
            <Link to="/" className="whyus__container-card">
              <div className="whyus__container-cardInfo">
                <h3>OLD AGE HOME</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default Work;
